import { Icon } from "@iconify/react";
import { useRef, useState, useEffect } from "react";
import homeFill from "@iconify/icons-eva/home-fill";
import personFill from "@iconify/icons-eva/person-fill";
import briefcaseFill from '@iconify/icons-eva/briefcase-fill';
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import { Link as RouterLink } from "react-router-dom";
// material
import { alpha } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Tooltip,
} from "@material-ui/core";
// components
import { MIconButton } from "../../components/@material-extend";
import MenuPopover from "../../components/MenuPopover";
import useAuth from "../../hooks/useAuth";
import { PATH_DASHBOARD } from "routes/paths";
import {
  getClientCommit,
  menuVersionApp,
} from "components/app-info/FooterInfo";
import { urlBackNestApp } from "routes/urls";
import Feedback from "../../components/app-info/Feedback";
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  { label: "Home", icon: homeFill, linkTo: "/" },
  { label: "Profile", icon: personFill, linkTo: PATH_DASHBOARD.app.myprofile },
  //{ label: "Settings", icon: settings2Fill, linkTo: "#" },
];

// ----------------------------------------------------------------------

export default function AccountPopover({ userInfo }: any) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { logout, user } = useAuth();
  const [clientInfo, setClientInfo] = useState<any>();
  const [serverInfo, setServerInfo] = useState<any>();
  const [feedback, setFeedback] = useState<any>([]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   const { commitInfo } = getClientCommit();
  //   getServerCommit();
  //   setClientInfo(commitInfo);
  // }, []);

  useEffect(() => {
    setFeedback(menuVersionApp([clientInfo, serverInfo]));
  }, [clientInfo, serverInfo]);

  async function getServerCommit() {
    const resp = await fetch(`${urlBackNestApp}/app-info/last-commit`);
    const json = await resp.json();
    setServerInfo(json);
  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar
          alt="My Avatar"
          src="/static/mock-images/avatars/avatar_default.jpg"
        />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user!.userName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user!.userName}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option, index: number) => (
          <MenuItem
            key={100 + index}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Tooltip title={"My Organization"} placement="bottom-end">
          <MenuItem
            to={"#"}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Typography variant="body2" style={{ color: "#0045FF" }}>
              Org :
            </Typography>
            {userInfo?.name || ""}
          </MenuItem>
        </Tooltip>

        {feedback.map((item: any, index: number) => (
          <Tooltip key={200 + index} title={item.title} placement="bottom-end">
            <MenuItem
              to={item.to}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: "body2", py: 1, px: 2.5 }}
            >
              <Typography variant="body2" style={{ color: item.color }}>
                {item.type} :
              </Typography>
              {item.date}
            </MenuItem>
          </Tooltip>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Feedback env={feedback} />
        </Box>
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="contained"
            onClick={logout}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
