// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import {
  Assignment,
  AssignmentOutlined,
  BusinessOutlined,
  FaceOutlined,
  PeopleOutline,
  PersonOutline
} from "@material-ui/icons";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: <PeopleOutline/>,
  orders: <AssignmentOutlined/>,
  organizations: <BusinessOutlined/>,
  users: <FaceOutlined/>,
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard')
};

const sidebarConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'General',
    items: [
      {
        title: 'Patients',
        path: PATH_DASHBOARD.app.patients,
        icon: ICONS.user,
      },
      {
        title: 'Request',
        path: PATH_DASHBOARD.app.request,
        icon: ICONS.orders,
      },
      {
        title: 'Organizations',
        path: PATH_DASHBOARD.app.organizations,
        icon: ICONS.organizations,
      },
      {
        title: 'Users',
        path: PATH_DASHBOARD.app.users,
        icon: ICONS.users,
      },
    ]
  }
];

export default sidebarConfig;
