import { Alert, AlertTitle, Container } from '@material-ui/core';
import useAuth from 'hooks/useAuth';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  component: any;
  roles: Array<string>;
};

export const ACLS: string[] = [
  'Sys.Admin',
  'Org.Admin',
  'Patient.ClinicalInfo.View',
  'Patient.Elegibility.Edit',
  'Patient.Score.Edit',
  'Patient.Upload',
  'Patient.Add',
  'Patient.Access',
  'Requests.Access',
  'Users.Access',
  'Organizations.Access',
  'Patient.Delete',
  'Request.Delete',
  'Request.Print'
];

export const InckNatPortal: string = 'InCK.NAT.Portal.User';
export const SysAdminAcl: string = 'Sys.Admin';
export const OrgAdminAcl: string = 'Org.Admin';
export const PatientClinicalInfoViewAcl: string = 'Patient.ClinicalInfo.View';
export const PatientElegibilityEditAcl: string = 'Patient.Elegibility.Edit';
export const PatientScoreEditAcl: string = 'Patient.Score.Edit';
export const PatientAddAcl: string = 'Patient.Add';
export const PatientUploadAcl: string = 'Patient.Upload';
export const PatientAccessAcl: string = 'Patient.Access';
export const RequestAccessAcl: string = 'Requests.Access';
export const UsersAccessAcl: string = 'Users.Access';
export const OrganizationAccessAcl: string = 'Organizations.Access';
export const PatientDeleteAcl: string = 'Patient.Delete';
export const RequestDeleteAcl: string = 'Request.Delete';
export const RequestPrintAcl: string = 'Request.Print';
export const RequestReprocessAcl: string = 'Request.Reprocess';

export function checkAclValidation(validation: any): boolean {
  const { user, roles, acl } = validation;

  if (acl === 'roleGuard') {
    const isAclValid = user!.acl.filter((item: any) =>/*  */
      roles.includes(item.name)
    );
    if (isAclValid && !isAclValid[0]) {
      return true;
    }
  }

  return Boolean(user!.acl.find((item: any) => item.name === acl));
}

export default function RoleBasedGuard({
  component: Component,
  roles,
  ...rest
}: RoleBasedGuardProp) {
  const { user } = useAuth();

  if (checkAclValidation({ user, roles, acl: 'roleGuard' })) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You don't have permission to access this page, contact your provider.
        </Alert>
      </Container>
    );
  }

  return <Component {...rest} />;
}
