import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession, decodedSign } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';
// ----------------------------------------------------------------------
import Cookies from 'js-cookie';
import faker from "faker";
import {urlBackNestApp} from "../routes/urls";

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: any;
  };
  [Types.Login]: {
    user: any;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const encryptSign = localStorage.getItem('encryptUser');
        const cookie = Cookies.get('access_token');
        const encryptCookie = Cookies.get('encrypt_user');

        if (accessToken && encryptSign && ( isValidToken(accessToken) || process.env.REACT_APP_DEVELOPMENT_MODE==="true" ) ) {
          setSession(accessToken, encryptSign);
          const user = decodedSign(accessToken)

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user
            }
          });
        }
        else if(cookie && encryptCookie){
          setSession(cookie, encryptCookie);
          const user = decodedSign(cookie)
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user
            }
          });
        }
        else if(process.env.REACT_APP_DEVELOPMENT_MODE!=="true"){
          setSession(null, null);
          const urlredirect: string = process.env.REACT_APP_KEYCLOAK_URL as string || '';
          window.location.href = urlredirect
        }
      } catch (err) {
        console.log("Hay error token")
        console.log(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {

    // Recibir la cookie y guardarla en localstorage
    // const cookie = Cookies.get('access_token');
    // console.log(typeof cookie)
    // if (typeof cookie === "string") {
    //   setSession(cookie);
    // }

    //const cookie ='jskjskljskjsljkjl'
    //console.log(typeof cookie)
    //setSession(cookie);
    setSession('login', 'encript')
    const response = await axios.post('/api/account/login', {
      email,
      password
    });

    const { accessToken, user } = response.data;

    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });

  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    const userdata = {'userName':state.user.userName};
    setSession(null, null);

    try {
      fetch(`${urlBackNestApp}/users/logout`,{
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userdata)
      }).then(() =>{
        dispatch({ type: Types.Logout });
        const urlredirect: string = process.env.REACT_APP_KEYCLOAK_URL as string || '';
        window.location.href = urlredirect
      })
    } catch(e) {
      console.log(`Something fail try to add user ${e}`)
    }

  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  return (
      <AuthContext.Provider
          value={{
            ...state,
            method: 'jwt',
            login,
            logout,
            register,
            resetPassword,
            updateProfile
          }}
      >
        {children}
      </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
